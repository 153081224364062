import moment from 'moment'

const DATE_FORMATS = {
  date: 'MMM D, YYYY',
  full: 'MMM D, YYYY h:mm A',
  fullWithTZ: 'MMM D, YYYY h:mm A Z'
}

export default function formatISODatetime(isoStr, kind = 'full') {
  if (isoStr) {
    return moment(isoStr).format(DATE_FORMATS[kind])
  }

  return '-'
}
