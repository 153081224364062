const EARTH_R_METER = 6356752

function rad2deg(rad) {
  return rad * 180 / Math.PI
}

function deg2rad(deg) {
  return deg * Math.PI / 180
}

function meter2rad(meter) {
  return meter / EARTH_R_METER
}

export function geoDirect(lat, lng, azimuth, distance) {
  const sigma = meter2rad(distance)
  const alpha = deg2rad(azimuth)
  const lat1 = deg2rad(lat)
  const lng1 = deg2rad(lng)

  const sinLat2 = Math.sin(lat1) * Math.cos(sigma) + Math.cos(lat1) * Math.sin(sigma) * Math.cos(alpha)
  const lat2 = Math.asin(sinLat2)

  // sin(lng2 - lng1)
  const sinDeltaLng = Math.sin(alpha) * Math.sin(sigma) / Math.cos(lat2)
  const lng2 = Math.asin(sinDeltaLng) + lng1

  return [rad2deg(lat2), rad2deg(lng2)]
}

export default function calcGrid(lat, lng, size, distance) {
  const halfStep = parseInt(size / 2, 10)
  const grid = []

  for (let i = 0; i < size; i++) {
    // Get leftmost point for current grid line.
    const a = halfStep * distance
    const b = (halfStep - i) * distance
    const c = Math.sqrt((a ** 2) + (b ** 2))
    const alpha = 180 * Math.asin(b / c) / Math.PI
    const topLeft = geoDirect(lat, lng, -(90 - alpha), c)

    grid.push(topLeft)

    // Calculate other points of current line.
    for (let j = 1; j < size; j++) {
      const point = geoDirect(topLeft[0], topLeft[1], 90, j * distance)
      grid.push(point)
    }
  }

  return grid
}

export function calcGridPointBoundaries(grid) {
  const size = Math.sqrt(grid.length)
  const horizontalHalf = Math.abs(grid[0][0] - grid.at(-1)[0]) / (size - 1) / 2
  const verticalHalf = Math.abs(grid[0][1] - grid.at(-1)[1]) / (size - 1) / 2

  return grid.map(([x, y]) => [
    [x + horizontalHalf, y + verticalHalf],
    [x - horizontalHalf, y + verticalHalf],
    [x - horizontalHalf, y - verticalHalf],
    [x + horizontalHalf, y - verticalHalf],
    [x + horizontalHalf, y + verticalHalf]
  ])
}
