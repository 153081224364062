import moment from 'moment'
import Cookies from 'js-cookie'

export const TIME_FORMAT = 'MM/DD/YYYY'

export function compileDate(date) {
  return [date.year(), date.month(), date.date()]
}
export function compileRanges(dates) {
  const {
    currStart, currEnd, prevStart, prevEnd
  } = dates
  return [
    { start: compileDate(currStart), end: compileDate(currEnd) },
    { start: compileDate(prevStart), end: compileDate(prevEnd) }
  ]
}

export function formatDate(date, timeFormat = TIME_FORMAT) {
  return moment.utc(date).format(timeFormat)
}

export function humanizeRangeDate(dateRange) {
  const { start, end } = dateRange
  let rangeTitle = ''
  if (start && end) {
    const startDate = moment.utc(start, TIME_FORMAT)
    const endDate = moment.utc(end, TIME_FORMAT)
    if (endDate.diff(startDate, 'years') !== 0) {
      rangeTitle = `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`
    } else if (endDate.diff(startDate, 'months') !== 0 || startDate.month() !== endDate.month()) {
      rangeTitle = `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`
    } else if (endDate.diff(startDate, 'days') !== 0) {
      rangeTitle = `${startDate.format('D')} - ${endDate.format('MMM D, YYYY')}`
    } else {
      rangeTitle = `${endDate.format('MMM D, YYYY')}`
    }
  }

  return rangeTitle
}

export function setRangeToCookie(range) {
  Cookies.set('startDate', range.start, { expires: 1 })
  Cookies.set('endDate', range.end, { expires: 1 })
}

export default function datepicker() {
  return [
    [
      moment.utc().startOf('isoWeek').format(TIME_FORMAT),
      moment.utc().endOf('isoWeek').format(TIME_FORMAT)
    ],
    [
      moment.utc().subtract(1, 'weeks').startOf('isoWeek').format(TIME_FORMAT),
      moment.utc().subtract(1, 'weeks').endOf('isoWeek').format(TIME_FORMAT)
    ]
  ]
}
