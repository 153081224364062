<template>
  <div class="card-search-term__bottom">
    <div class="card-search-term__metrics">
      <div class="card-search-term__metric">
        <br>
        <p>cur:</p>
        <p>avg:</p>
      </div>
      <div v-for="({ name, value, delta, deltaFormatter, average }, metricId) in metrics"
        class="card-search-term__metric"
        :class="{ active: metricId === chartMetric }"
        :key="`metric-${metricId}`"
        @click="selectChartMetric(metricId)">
        <span>{{ name }}:</span>
        <b>
          <span>{{ value }}</span>
          <span v-if="delta"
            class="card-search-term__delta"
            :class="{ 'card-search-term__delta--positive': delta < 0 }">
            <i class="fa-solid fa-triangle" />
            <span>
              {{ deltaFormatter ? deltaFormatter(delta) : delta }}
            </span>
          </span>
        </b>
        <p>{{ average }}</p>
      </div>
    </div>
    <div class="card-search-term__chart">
      <SearchTermChart
        v-if="showChart && hasHistoricalData"
        :data="chartMetricData"
        :metric="chartMetric"
        :average="chartAverage" />
      <span v-else-if="!hasHistoricalData">No historical data</span>
    </div>
  </div>
</template>

<script>
import SearchTermChart from './search_term_line_chart'

export default {
  components: { SearchTermChart },
  props: {
    st: { type: Object, required: true },
    chartMetric: { type: String, required: true }
  },
  data() {
    return {
      showChart: true
    }
  },
  computed: {
    metrics() {
      const NA = 'N/A'
      // Yes, it is indeed fun to work with floats that are represented as strings on backend for some reason
      const formatDecimalMetric = (num) => (num ? parseFloat(num).toFixed(2) : NA)
      const formatSolv = (num) => (num || num === 0 ? `${Math.round(parseFloat(parseFloat(num).toFixed(2)) * 100)}%` : NA)
      const getDelta = (cur, prev) => {
        const curNumber = parseFloat(cur)
        const prevNumber = parseFloat(prev)
        if (Number.isNaN(curNumber) || Number.isNaN(prevNumber)) {
          return 0
        }

        const diff = curNumber - prevNumber
        return diff
      }

      const prevAgr = this.st.metricHistory?.agrsHistory.at(-2)
      const prevAtgr = this.st.metricHistory?.atgrsHistory.at(-2)
      const prevSolv = this.st.metricHistory?.solvsHistory.at(-2)

      return {
        agr: {
          name: 'AGR',
          value: formatDecimalMetric(this.st.lastGeogrid.agr),
          delta: getDelta(this.st.lastGeogrid.agr, prevAgr),
          deltaFormatter: (delta) => formatDecimalMetric(Math.abs(delta)),
          average: formatDecimalMetric(this.st.averageGeogridData.agr)
        },
        atgr: {
          name: 'ATGR',
          value: formatDecimalMetric(this.st.lastGeogrid.atgr),
          delta: getDelta(this.st.lastGeogrid.atgr, prevAtgr),
          deltaFormatter: (delta) => formatDecimalMetric(Math.abs(delta)),
          average: formatDecimalMetric(this.st.averageGeogridData.atgr)
        },
        solv: {
          name: 'SolV',
          value: formatSolv(this.st.lastGeogrid.solv),
          // solv good/bad logic is inverted
          delta: getDelta(this.st.lastGeogrid.solv, prevSolv) * (-1),
          deltaFormatter: (delta) => formatSolv(Math.abs(delta)),
          average: formatSolv(this.st.averageGeogridData.solv)
        }
      }
    },
    hasHistoricalData() {
      return this.st.metricHistory?.agrsHistory && this.st.metricHistory.agrsHistory.length > 1
    },
    chartMetricData() {
      return this.st.metricHistory[`${this.chartMetric}sHistory`]
    },
    chartAverage() {
      return this.st.averageGeogridData[this.chartMetric]
    }
  },
  methods: {
    selectChartMetric(metricName) {
      this.$emit('selectChartMetric', metricName)
    }
  },
  watch: {
    st: {
      handler() {
        if (!this.canDrawChart) {
          return
        }

        this.showChart = false
        this.$nextTick(() => {
          this.showChart = true
        })
      },
      deep: true
    },
    canDrawChart: {
      handler(val) {
        this.$nextTick(() => {
          this.showChart = val
        })
      }
    }
  }
}
</script>
