import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys-deep'
import Qs from 'qs'

const JSON_TYPE = 'application/json'

export default {
  transformRequest(hsh) {
    return hsh ? Qs.stringify(snakeCaseKeys(hsh, { deep: true }), { arrayFormat: 'brackets' }) : hsh
  },
  transformResponse(str, xhr) {
    if (xhr['content-type'] === JSON_TYPE || xhr['content-type'].includes(JSON_TYPE)) return camelCaseKeys(JSON.parse(str))
    return str
  }
}
