var base;

if (window.Styxie == null) {
  window.Styxie = {};
}

window.Styxie.Initializers = {};

if ((base = window.Styxie).initQueue == null) {
  base.initQueue = [];
}

window.Styxie.applyInitializer = function(klass, method, json) {
  var initializer;
  initializer = this.Initializers[klass];
  if (!initializer) {
    return;
  }
  if (initializer.initialize) {
    initializer.initialize(json);
  }
  if (initializer[method]) {
    return initializer[method](json);
  }
};

window.Styxie.deferredInit = function() {
  var args, i, len, ref;
  ref = this.initQueue;
  for (i = 0, len = ref.length; i < len; i++) {
    args = ref[i];
    this.applyInitializer.apply(this, args);
  }
  return this.initQueue = [];
};

window.initStyxie = function() {
  return window.Styxie.deferredInit();
};
