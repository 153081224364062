export function omit(obj, keys) {
  return Object.keys(obj)
    .filter((key) => !keys.includes(key))
    .reduce((memo, key) => {
      memo[key] = obj[key]
      return memo
    }, {})
}

export function isEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }
  return true
}

export function toDataUrl(url, callback) {
  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }

  xhr.onerror = () => {
    window.open(url, '_blank')
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export function differenceClass(diff) {
  if (diff === 0) return 'equal'
  if (diff > 0) return 'positive'
  return 'negative'
}

export function extractSessionStorage(key) {
  return JSON.parse(sessionStorage.getItem(key))
}

export function insertSessionStorage(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export function pick(obj, keys) {
  return keys.reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {})
}

export function populateLocalStorage(attrs) {
  Object.keys(attrs).forEach((key) => {
    localStorage.setItem(key, attrs[key])
  })
}

export function fetchFromLocalStorage(keys) {
  return keys.reduce((acc, key) => {
    if (localStorage.getItem(key)) {
      acc[key] = localStorage.getItem(key)
    }
    return acc
  }, {})
}
