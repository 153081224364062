<template>
  <div :class="['geogrid-preview', `size-${rows.length}`]">
    <div class="tr" v-for="row in rows">
      <div class="td" :class="`gg-background--gg${rank === '!' ? 'error' : rank}`" v-for="rank in row" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeogridPreview',
  props: {
    ranks: Array
  },
  data() {
    return {
      rows: []
    }
  },
  watch: {
    ranks(value) {
      this.rows = value || []
    }
  },
  mounted() {
    this.rows = this.ranks || []
  }
}
</script>
